<template>
  <article class="view-user-profile">
    <div class="site-wrapper rythm-v">
      <header>
        <h1>Mon compte</h1>
      </header>

      <section class="section--subscription card shadow rythm-v" v-if="userData && userData.subscription">
        <h2>Votre abonnement CrossDoc</h2>

        <div>
          <div class="rythm-v">
            <!-- Status -->
            <p v-if="subscription_is_active" role="alert" class="alert-success">
              {{ subscription_active_message }}
            </p>

            <p v-else role="alert" class="alert-warning">
              Votre abonnement est inactif !
            </p>

            <!-- Messages -->

            <!-- Subscription has ended (inactive) -->
            <div v-if="subscription_has_ended">
              <p>
                Il s'est terminé le
                <strong>{{ timestampToDate(userData.subscription.ended_at) }}</strong
                >.
              </p>
            </div>

            <!-- Subscription was canceled but has not ended yet -->
            <div v-if="subscription_is_canceled && !subscription_has_ended">
              <p>
                ⚠️ Il a été annulé le
                <strong>{{ timestampToDate(userData.subscription.canceled_at) }}</strong
                >.
              </p>

              <p v-if="parseInt(userData.subscription.current_period_end) > 0">
                Il se terminera le
                <strong>{{ timestampToDate(userData.subscription.current_period_end) }}</strong
                >, <strong>vous n'aurez alors plus accès à CrossDoc.</strong>
              </p>
            </div>
          </div>

          <!-- Subscription is active -->
          <!-- userData.subscription.status !== 'registration' && !subscription_has_ended -->
          <!-- v-if="subscription_is_active" -->
          <div class="input-group--controls">
            <button
              v-if="userData.subscription.status !== 'registration' && !subscription_has_ended && !!userData.subscription.customer_id"
              type="button"
              class="button--secondary"
              @click="createCustomerPortalSession()"
            >
              Gérer mon abonnement
            </button>
          </div>
        </div>

        <section class="section--payment" v-if="!subscription_is_active">
          <div class="input-group--controls controls--payment">
            <!-- Subscription is inactive -->
            <!-- userData.subscription.status === 'registration' || subscription_has_ended -->
            <div>
              <button type="button" class="button--primary" @click="createCheckoutSession()">
                <svg class="icon" width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0H13.5C13.8978 0 14.2794 0.158035 14.5607 0.43934C14.842 0.720644 15 1.10218 15 1.5V9.5C15 9.89783 14.842 10.2794 14.5607 10.5607C14.2794 10.842 13.8978 11 13.5 11H1.5C1.10217 11 0.720644 10.842 0.43934 10.5607C0.158035 10.2794 0 9.89783 0 9.5V1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934ZM1.5 1C1.36739 1 1.24021 1.05268 1.14645 1.14645C1.05268 1.24021 1 1.36739 1 1.5V3H14V1.5C14 1.36739 13.9473 1.24021 13.8536 1.14645C13.7598 1.05268 13.6326 1 13.5 1H1.5ZM14 4H1V9.5C1 9.63261 1.05268 9.75978 1.14645 9.85355C1.24022 9.94732 1.36739 10 1.5 10H13.5C13.6326 10 13.7598 9.94732 13.8536 9.85355C13.9473 9.75978 14 9.63261 14 9.5V4ZM2 7H8V8H2V7ZM10 7H13V8H10V7Z"
                    fill="currentColor"
                  />
                </svg>

                <span class="caption">Régler par carte bancaire</span>
              </button>
            </div>
          </div>
        </section>
      </section>

      <section class="section--profile card shadow rythm-v" v-if="userData">
        <h2>Informations professionnelles</h2>

        <form method="post" @submit.prevent="updateUserData">
          <div class="flex-row flex-center-v">
            <div class="flex-item--50">
              <div class="input-group required">
                <label for="firstname">Prénom</label>
                <input type="text" name="firstname" id="firstname" v-model="userData.firstname" required />
              </div>
            </div>

            <div class="flex-item--50">
              <div class="input-group required">
                <label for="lastname">Nom</label>
                <input type="text" name="lastname" id="lastname" v-model="userData.lastname" required />
              </div>
            </div>

            <div class="flex-item--30 flex-item--grow flex-row flex-align-top avatar-wrapper">
              <div class="input-group input-avatar">
                <label for="avatar" :class="validations.avatar.invalid ? 'invalid' : ''"
                  >Photo / Avatar <small>{{ validations.avatar.error }}</small></label
                >
                <input type="file" name="avatar" id="avatar" ref="avatar" accept="image/*" :invalid="validations.avatar.invalid" @change="setAvatarPreview" />
              </div>
              <img v-if="avatarPreviewSrc && !validations.avatar.invalid" :src="avatarPreviewSrc" class="avatar-preview" alt="Avatar" width="128" height="auto" />
            </div>

            <div class="flex-item--30 flex-item--grow">
              <p class="label required">Pays</p>
              <div class="input-group">
                <select name="country" id="country" required @change="setCountry">
                  <option value="">Aucune sélection</option>
                  <option v-for="(country, index) in lists.countries" :key="`country_${index}`" :value="country.code" :selected="country.code === userData.country">{{ country.name }}</option>
                </select>
              </div>
            </div>

            <div class="flex-item--100 flex-row flex-align-top" v-if="userData.country === 'FR'">
              <div class="flex-item--50">
                <p class="label required">Identifiant professionnel <span class="small">(soumis à vérification)</span></p>
                <div class="input-group">
                  <div class="input-group--inline input-group-radio">
                    <label>
                      <input type="radio" name="pro_id" value="rpps" v-model="pro_id" required />
                      <span class="caption">R.P.P.S.</span>
                    </label>
                    <label>
                      <input type="radio" name="pro_id" value="adeli" v-model="pro_id" required />
                      <span class="caption">ADELI</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="flex-item--50" v-if="pro_id == 'rpps'">
                <div class="input-group">
                  <label class="label-rpps" for="rpps">
                    <span class="caption">N° R.P.P.S</span>
                    <span
                      class="info"
                      v-tooltip="{
                        content: tooltip_rpps,
                        placement: 'top-center',
                        classes: ['info'],
                        delay: {
                          show: 100,
                          hide: 500,
                        },
                        offset: 10,
                        trigger: 'hover focus click',
                      }"
                      ><svg class="icon icon-info" role="img" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                          clip-rule="evenodd"
                        />
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M11 10C11.5523 10 12 10.4477 12 11V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V11C10 10.4477 10.4477 10 11 10Z"
                          clip-rule="evenodd"
                        />
                        <path fill="currentColor" d="M12 7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7Z" />
                      </svg>
                    </span>
                  </label>

                  <input
                    type="text"
                    name="rpps"
                    id="rpps"
                    v-model="userData.rpps"
                    pattern="^(\d{11})$"
                    minlength="11"
                    maxlength="11"
                    placeholder="Exemple : 10000123456"
                    :class="rppsValidates"
                    :required="this.pro_id === 'rpps'"
                  />
                </div>
              </div>

              <div class="flex-item--50" v-if="pro_id == 'adeli'">
                <div class="input-group">
                  <label class="label-adeli" for="adeli">
                    <span class="caption">N° ADELI</span>
                    <span
                      class="info"
                      v-tooltip="{
                        content: tooltip_adeli,
                        placement: 'top-center',
                        classes: ['info'],
                        delay: {
                          show: 100,
                          hide: 500,
                        },
                        offset: 10,
                        trigger: 'hover focus click',
                      }"
                      ><svg class="icon icon-info" role="img" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                          clip-rule="evenodd"
                        />
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M11 10C11.5523 10 12 10.4477 12 11V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V11C10 10.4477 10.4477 10 11 10Z"
                          clip-rule="evenodd"
                        />
                        <path fill="currentColor" d="M12 7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7Z" />
                      </svg>
                    </span>
                  </label>

                  <input
                    type="text"
                    name="adeli"
                    id="adeli"
                    v-model="userData.adeli"
                    pattern="^([a-zA-Z0-9]{9})$"
                    minlength="9"
                    maxlength="9"
                    placeholder="Exemple : 123456782"
                    :class="adeliValidates"
                    :required="this.pro_id === 'adeli'"
                  />
                </div>
              </div>
            </div>

            <div class="flex-item--30">
              <p class="label required">Spécialité</p>
              <div class="input-group">
                <select name="specialty" id="specialty" required @change="setSpecialty">
                  <option value="">Aucune sélection</option>
                  <option v-for="(specialty, index) in lists.specialties" :key="index" :value="specialty" :selected="specialty === userData.specialty">{{ specialty }}</option>
                </select>
              </div>
            </div>

            <div class="flex-item--30">
              <div class="input-group required">
                <label for="city">Ville d'exercice</label>
                <input type="text" name="city" id="city" v-model="userData.city" required />
              </div>
            </div>

            <div class="flex-item--30">
              <div class="input-group required">
                <label for="zip">Code postal</label>
                <input type="text" name="zip" id="zip" v-model="userData.zip" required />
              </div>
            </div>

            <div class="flex-item--50">
              <p class="label required">Type d'exercice</p>
              <div class="input-group">
                <select name="practices" id="practices" @change="setPractice" required>
                  <option value="">Aucune sélection</option>
                  <option v-for="(practice, index) in lists.practices" :key="index" :value="practice" :selected="practice === userData.practice">{{ practice }}</option>
                </select>
              </div>
            </div>

            <div class="flex-item--50">
              <div class="input-group">
                <label for="practice_other">Autre type d'exercice</label>
                <input type="text" name="practice_other" id="practice_other" v-model="userData.practice_other" />
              </div>
            </div>

            <div class="flex-item--100">
              <p class="label required">
                Souhaitez-vous recevoir la newsletter mensuelle Crossdoc (Actualités, congrès, questions les plus intéressantes…)&nbsp;?
              </p>
              <div class="input-group--inline input-group-radio">
                <label for="newsletter-yes">
                  <input type="radio" id="newsletter-yes" name="newsletter" value="1" v-model="userData.newsletter" required />
                  <span class="caption">Oui</span>
                </label>
                <label for="newsletter-no">
                  <input type="radio" id="newsletter-no" name="newsletter" value="0" v-model="userData.newsletter" required />
                  <span class="caption">Non</span>
                </label>
              </div>
            </div>

            <div class="input-group--controls flex-item--100">
              <button type="submit" class="button--secondary">
                <svg class="icon icon-check" role="img" xmlns="http://www.w3.org/2000/svg" width="18" height="13" fill="none" viewBox="0 0 18 13">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="caption">Enregistrer</span>
              </button>
            </div>
          </div>
        </form>
      </section>

      <section class="section--password card shadow rythm-v">
        <h2>Changer de mot de passe</h2>

        <form @submit.prevent="updatePassword">
          <div class="flex-row">
            <div class="flex-item--50">
              <div class="input-group">
                <label for="password">Mot de passe <span class="small">(12 caractères minimum)</span></label>
                <input type="password" name="password" id="password" v-model="updatePasswordData.password" :invalid="passwordIsInvalid" required />
              </div>
            </div>

            <div class="flex-item--50">
              <div class="input-group">
                <label for="password-check">Mot de passe <span class="small">(vérification)</span></label>
                <input type="password" name="password-check" id="password-check" v-model="updatePasswordData.passwordCheck" :invalid="passwordIsInvalid" required />
              </div>
            </div>
          </div>

          <ul class="form-validations">
            <li v-for="(validate, index) in passwordValidations" :key="index">
              <svg
                role="img"
                :class="validate.checked ? 'icon icon-checkmark checked' : 'icon icon-checkmark'"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="none"
                viewBox="0 0 22 22"
              >
                <svg:style>
                  .icon-checkmark .icon-checked {
                    opacity: 0;
                    fill: #3aaa35;
                  }
                  .icon-checkmark .icon-unchecked {
                    fill: #95c11f;
                    opacity: 1;
                  }

                  .icon-checkmark.checked .icon-checked {
                    opacity: 1;
                  }
                  .icon-checkmark.checked .icon-unchecked {
                    opacity: 0;
                  }
                </svg:style>
                <path
                  class="icon-unchecked"
                  fill="#95c11f"
                  fill-rule="evenodd"
                  d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                  clip-rule="evenodd"
                />
                <g class="icon-checked">
                  <path
                    fill="#3AAA35"
                    fill-rule="evenodd"
                    d="M14.663 2.77915C12.8902 1.98926 10.9096 1.79357 9.01657 2.22128C7.12351 2.649 5.41942 3.67719 4.15845 5.15252C2.89749 6.62785 2.14721 8.47126 2.01951 10.4078C1.89181 12.3444 2.39354 14.2704 3.44987 15.8985C4.50619 17.5266 6.06051 18.7697 7.88102 19.4423C9.70153 20.1149 11.6907 20.1809 13.5518 19.6307C15.413 19.0804 17.0464 17.9432 18.2084 16.3888C19.3705 14.8344 19.9989 12.9459 20 11.0052V10.0857C20 9.53344 20.4477 9.08572 21 9.08572C21.5523 9.08572 22 9.53344 22 10.0857V11.0057C21.9986 13.3778 21.2306 15.6864 19.8103 17.5863C18.39 19.4862 16.3936 20.876 14.1189 21.5486C11.8442 22.2211 9.41299 22.1404 7.18792 21.3183C4.96285 20.4963 3.06312 18.977 1.77206 16.9871C0.480993 14.9971 -0.13223 12.6432 0.0238445 10.2762C0.179919 7.90932 1.09693 5.65626 2.63811 3.85308C4.17929 2.0499 6.26206 0.793217 8.57581 0.270457C10.8896 -0.252303 13.3103 -0.0131332 15.477 0.952297C15.9815 1.17708 16.2082 1.76825 15.9834 2.27272C15.7587 2.7772 15.1675 3.00393 14.663 2.77915Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="#3AAA35"
                    fill-rule="evenodd"
                    d="M21.7068 2.29816C22.0975 2.68848 22.0978 3.32165 21.7075 3.71237L11.7075 13.7224C11.52 13.9101 11.2656 14.0155 11.0002 14.0156C10.7349 14.0157 10.4805 13.9103 10.2929 13.7227L7.29289 10.7227C6.90237 10.3322 6.90237 9.69903 7.29289 9.30851C7.68342 8.91798 8.31658 8.91798 8.70711 9.30851L10.9996 11.601L20.2925 2.29886C20.6829 1.90814 21.316 1.90783 21.7068 2.29816Z"
                    clip-rule="evenodd"
                  />
                </g>
              </svg>

              <span class="caption">{{ validate.caption }}</span>
            </li>
          </ul>

          <div class="input-group--controls">
            <button type="submit" class="button--secondary" :disabled="!formValidates(passwordValidations)">
              <svg class="icon icon-check" role="img" xmlns="http://www.w3.org/2000/svg" width="18" height="13" fill="none" viewBox="0 0 18 13">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="caption">Modifier le mot de passe</span>
            </button>
          </div>
        </form>
      </section>

      <section class="section--email card shadow rythm-v">
        <h2>Changer d'adresse e-mail</h2>

        <form @submit.prevent="requestEmailUpdate">
          <div class="flex-row flex-align-bottom">
            <div class="flex-item--50">
              <div class="input-group">
                <label for="email">E-mail <span class="small"></span></label>
                <input type="email" name="email" id="email" v-model="updateEmailData.email" :invalid="emailIsInvalid" required />
              </div>
            </div>
            <ul class="form-validations">
              <li v-for="(validate, index) in emailValidations" :key="index">
                <svg
                  role="img"
                  :class="validate.checked ? 'icon icon-checkmark checked' : 'icon icon-checkmark'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="none"
                  viewBox="0 0 22 22"
                >
                  <svg:style>
                    .icon-checkmark .icon-checked {
                      opacity: 0;
                      fill: #3aaa35;
                    }
                    .icon-checkmark .icon-unchecked {
                      fill: #95c11f;
                      opacity: 1;
                    }

                    .icon-checkmark.checked .icon-checked {
                      opacity: 1;
                    }
                    .icon-checkmark.checked .icon-unchecked {
                      opacity: 0;
                    }
                  </svg:style>
                  <path
                    class="icon-unchecked"
                    fill="#95c11f"
                    fill-rule="evenodd"
                    d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                    clip-rule="evenodd"
                  />
                  <g class="icon-checked">
                    <path
                      fill="#3AAA35"
                      fill-rule="evenodd"
                      d="M14.663 2.77915C12.8902 1.98926 10.9096 1.79357 9.01657 2.22128C7.12351 2.649 5.41942 3.67719 4.15845 5.15252C2.89749 6.62785 2.14721 8.47126 2.01951 10.4078C1.89181 12.3444 2.39354 14.2704 3.44987 15.8985C4.50619 17.5266 6.06051 18.7697 7.88102 19.4423C9.70153 20.1149 11.6907 20.1809 13.5518 19.6307C15.413 19.0804 17.0464 17.9432 18.2084 16.3888C19.3705 14.8344 19.9989 12.9459 20 11.0052V10.0857C20 9.53344 20.4477 9.08572 21 9.08572C21.5523 9.08572 22 9.53344 22 10.0857V11.0057C21.9986 13.3778 21.2306 15.6864 19.8103 17.5863C18.39 19.4862 16.3936 20.876 14.1189 21.5486C11.8442 22.2211 9.41299 22.1404 7.18792 21.3183C4.96285 20.4963 3.06312 18.977 1.77206 16.9871C0.480993 14.9971 -0.13223 12.6432 0.0238445 10.2762C0.179919 7.90932 1.09693 5.65626 2.63811 3.85308C4.17929 2.0499 6.26206 0.793217 8.57581 0.270457C10.8896 -0.252303 13.3103 -0.0131332 15.477 0.952297C15.9815 1.17708 16.2082 1.76825 15.9834 2.27272C15.7587 2.7772 15.1675 3.00393 14.663 2.77915Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill="#3AAA35"
                      fill-rule="evenodd"
                      d="M21.7068 2.29816C22.0975 2.68848 22.0978 3.32165 21.7075 3.71237L11.7075 13.7224C11.52 13.9101 11.2656 14.0155 11.0002 14.0156C10.7349 14.0157 10.4805 13.9103 10.2929 13.7227L7.29289 10.7227C6.90237 10.3322 6.90237 9.69903 7.29289 9.30851C7.68342 8.91798 8.31658 8.91798 8.70711 9.30851L10.9996 11.601L20.2925 2.29886C20.6829 1.90814 21.316 1.90783 21.7068 2.29816Z"
                      clip-rule="evenodd"
                    />
                  </g>
                </svg>

                <span class="caption" v-html="validate.caption"></span>
              </li>
            </ul>
          </div>

          <div class="input-group--controls">
            <button type="submit" class="button--secondary" :disabled="!formValidates(emailValidations)">
              <svg class="icon icon-check" role="img" xmlns="http://www.w3.org/2000/svg" width="18" height="13" fill="none" viewBox="0 0 18 13">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="caption">Modifier l'e-mail</span>
            </button>
          </div>
        </form>
      </section>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import "src/scss/register-profile.scss";

[role="alert"] > * + * {
  padding-left: 0.5em;
}

pre {
  white-space: pre-line;
  background: white;
  border: 1px solid #efefef;
  border-radius: 6px;
  padding: 1em;
}

.form-validations {
  margin-top: 1rem;
}

.section--email .form-validations {
  margin-bottom: 1rem;
  margin-top: 0;
  margin-left: 1rem;
}

.input-group--controls {
  justify-content: flex-end;

  &.controls--payment {
    button {
      text-align: left;
    }
  }
}

.checkbox-details {
  padding-left: 1.5rem;
}

input:not(:checked) + label + .checkbox-details {
  @include offscreen();
}

input:checked + label {
  font-weight: 600;
}

@media all and (max-width: 768px) {
  [class*="flex-item"].flex-item--30 {
    flex-basis: 100% !important;
  }

  .section--email .form-validations {
    margin-bottom: 1rem;
    margin-top: 0;
    margin-left: 1rem;
  }
}
</style>

<script>
import { appConfig } from "../config.js";
import { HTTP } from "../http-common.js";
import { emailIsValid, timestamp_format_human } from "../libs/helpers";
import countries from "../libs/countries.json";

// Dashboard component
export default {
  name: "userProfile",

  props: ["userProfileID"],

  data: function() {
    return {
      // User profile
      userData: null,
      pro_id: "",

      // Avatar
      fileInput: null,
      avatarPreviewSrc: "",

      tooltip_rpps: "<strong>Le numéro R.P.P.S. est composé de 11 caractères.</strong>",
      tooltip_adeli: "<strong>Le numéro ADELI est composé de 9 caractères.</strong>",

      // radio/selects lists
      lists: {
        countries: countries,
        specialties: [
          "C.C.A.",
          "Diététicien",
          "Gastro-entérologue adultes",
          "Gastro-Pédiatre",
          "Infirmier",
          "Interne",
          "Kinésithérapeute",
          "Médecin généraliste",
          "Médecin P.M.I.",
          "Orthophoniste",
          "Pédiatre",
          "Pharmacien",
          "Psychomotricien",
          "Sage-femme",
          "Autre",
        ],
        practices: ["Cabinet", "Cabinet de groupe", "Clinique", "Exercice mixte", "Hospitalier", "Hospitalo-universitaire", "PMI", "Autre"],
        referrers: ["Congrès", "Industrie", "Bouche à oreille", "Moteur de recherche", "Autre"],
      },

      // Validations
      validations: {
        avatar: {
          invalid: false,
          error: "(image de 1Mo maximum)",
        },
      },

      // Password change
      passwordResetToken: null,
      passwordResetPending: false,
      passwordResetSuccess: false,
      updatePasswordData: {
        password: "",
        passwordCheck: "",
      },

      // Email change
      updateEmailData: {
        email: "",
      },
    };
  },

  computed: {
    subscription_active_message: function() {
      if (!this.userData && !this.userData.subscription) return "";

      let date = "";

      if (this.subscription_is_active && !this.subscription_is_canceled && !this.subscription_has_ended && this.userData.subscription.current_period_end > 0) {
        let current_period_end = this.timestampToDate(this.userData.subscription.current_period_end);
        if (current_period_end) date = `jusqu’au ${current_period_end}`;
      }

      return `Votre abonnement est actif ${date}`;
    },

    // Subscription is active
    subscription_is_active: function() {
      return this.userData.subscription && parseInt(this.userData.subscription.is_active);
    },

    // Subscription is canceled (maybe active)
    subscription_is_canceled: function() {
      return this.userData.subscription && parseInt(this.userData.subscription.canceled_at) > 0;
    },

    // Subscription has ended (inactive)
    subscription_has_ended: function() {
      return this.userData.subscription && !!parseInt(this.userData.subscription.ended_at);
    },

    rppsValidates() {
      if (!this.userData.rpps || !this.userData.rpps.length) return;
      let isValid = false;
      if (this.userData.rpps.length !== 11) isValid = false;
      else isValid = true;
      return isValid ? "is-valid" : "is-invalid";
    },

    adeliValidates() {
      if (!this.userData.adeli || !this.userData.adeli.length) return;
      let isValid = false;
      if (this.userData.adeli.length !== 9) isValid = false;
      else isValid = true;
      return isValid ? "is-valid" : "is-invalid";
    },

    passwordIsInvalid: function() {
      if (this.updatePasswordData.password === "" && this.updatePasswordData.passwordCheck === "") return false; // No data
      let passwordLength = this.passwordValidations.find((el) => el.type === "passwordLength");
      let passwordMatch = this.passwordValidations.find((el) => el.type === "passwordMatch");
      return !passwordLength.checked || !passwordMatch.checked;
    },

    emailIsInvalid: function() {
      if (this.updateEmailData.email === "") return false; // No data
      let invalidEmail = this.emailValidations.find((el) => el.type === "email" && !el.checked);
      return invalidEmail;
    },

    emailValidations: function() {
      let validations = [
        {
          type: "validEmail",
          caption: "L'adresse e-mail est valide.",
          checked: this.validateEmail(this.updateEmailData.email),
        },
      ];

      if (this.userData)
        validations.push({
          type: "validEmail",
          caption: `L'adresse e-mail est différente de <strong>${this.userData.email}</strong>`,
          checked: this.updateEmailData.email !== "" && this.validateEmail(this.updateEmailData.email) && this.updateEmailData.email !== this.userData.email,
        });

      return validations;
    },

    passwordValidations: function() {
      return [
        {
          type: "passwordLength",
          caption: "La longueur du mot de passe est de 12 caractères minimum.",
          checked: this.updatePasswordData.password.length >= 12,
        },
        {
          type: "passwordMatch",
          caption: "Les mots de passe correspondent.",
          checked: this.updatePasswordData.password != "" && this.updatePasswordData.passwordCheck != "" && this.updatePasswordData.password === this.updatePasswordData.passwordCheck,
        },
      ];
    },

    // Current user data
    user: function() {
      return this.$store.state.userData;
    },
  },

  methods: {
    // Load and Configure Stripe
    includeStripe(callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//js.stripe.com/v3/";
      if (callback) {
        object.addEventListener(
          "load",
          function(e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },

    configureStripe() {
      this.stripe = Stripe(appConfig.STRIPE_PUBLIC_API_KEY);
    },

    /**
     * Checkout Session pour l'abonnement CrossDOC (PriceID)
     */
    createCheckoutSession: function() {
      this.$store
        .dispatch("STRIPE_CHECKOUT_SESSION", {
          userID: this.userData.id,
        })
        .then((payload) => {
          // Call Stripe.js method to redirect to the new Checkout page
          this.stripe
            .redirectToCheckout({
              sessionId: payload.sessionId,
            })
            .then(function(data) {
              console.log({ data });
            });
        })
        .catch((error) => {
          let msg = "Une erreur est survenue !";
          return this.$toasted.global.appError({
            message: msg,
          });
        });
    },

    /**
     * Customer Portal Session
     */
    createCustomerPortalSession: function() {
      this.$toasted.global.appSuccess({
        message: "<strong>Vous allez être redirigé vers le portail client.</strong>",
      });

      this.$store
        .dispatch("STRIPE_CUSTOMER_PORTAL_SESSION", {
          userID: this.userProfileID,
        })
        .then((payload) => {
          if (!payload.url) {
            return this.$toasted.global.appError({
              message: "Une erreur est survenue !",
            });
          } else {
            window.setTimeout(function() {
              window.location.href = payload.url;
            }, 2000);
          }
        })
        .catch((error) => {
          let msg = "Une erreur est survenue !";
          return this.$toasted.global.appError({
            message: msg,
          });
        });
    },

    // Load user data from database
    refreshUser: function() {
      if (this.userData && this.userData.id && this.userData.id === this.user.id) {
        this.$store.dispatch("REFRESH_USER", this.userData.id).then((response) => {
          this.userData = response;
        });
      }
    },

    // Check if all validation object "checked" properties are true
    formValidates: function(validations) {
      let trues = 0;
      validations.forEach((el) => {
        if (el.checked) trues++;
      });
      return trues === validations.length;
    },

    // Check the validity of an email address
    validateEmail: function(email) {
      return emailIsValid(email);
    },

    // Format Unix Timestamp
    timestampToDate: function(timestamp) {
      let localDate = timestamp_format_human(timestamp, {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
      });
      return localDate;
    },

    // set country select value
    setCountry: function(e) {
      this.userData.country = e.target.value;
    },

    // set specialty select value
    setSpecialty: function(e) {
      this.userData.specialty = e.target.value;
    },

    // set practice select value
    setPractice: function(e) {
      this.userData.practice = e.target.value;
    },

    // display avatar preview
    setAvatarPreview: function(e) {
      let fileInput = e.target;
      if (fileInput.files && fileInput.files[0]) {
        this.validations.avatar.invalid = false;
        this.validations.avatar.error = "(image de 1Mo maximum)";

        // image preview
        let blob = URL.createObjectURL(fileInput.files[0]);
        if (blob) this.avatarPreviewSrc = blob;

        // file type
        if (fileInput.files[0].type.indexOf("image/") === -1) {
          this.validations.avatar.invalid = true;
          this.validations.avatar.error = "(Le fichier n'est pas une image)";
        }

        // file weight
        if (typeof FileReader !== "undefined") {
          let size = fileInput.files[0].size;
          if (size > 1000000) {
            this.validations.avatar.invalid = true;
          }
        }
      }
    },

    // Update Email
    updateEmail: function(token) {
      this.$toasted.global.appInfo({
        message: "Mise à jour de l'adresse e-mail en cours…",
      });
      let userID = this.userProfileID;

      this.$store
        .dispatch("UPDATE_EMAIL", { token: token, userID: userID })
        .then(() => {
          this.loadUserData();
          this.$toasted.global.appSuccess({
            message: "Votre adresse e-mail est modifiée !",
          });
        })
        .catch((error) => {
          let msg = "Ce lien de validation a expiré, impossible de modifier l'adresse e-mail.";

          if (error.status === 409) {
            msg = "Cette adresse e-mail est déjà utilisée&nbsp;!";
          }

          this.$toasted.global.appError({
            message: msg,
          });
        });
    },

    // Send email update validation (double opt-in)
    requestEmailUpdate: function() {
      let payload = this.updateEmailData;
      let userID = this.userProfileID;
      let email = this.updateEmailData.email;

      this.$store
        .dispatch("REQUEST_EMAIL_UPDATE", { payload: payload, userID: userID })
        .then(() => {
          this.$toasted.global.appSuccess({
            message: `<strong>Un lien a été envoyé à ${email} pour confirmer le changement d'adresse e-mail.</strong>
								<ul><li>Consultez votre boîte de réception</li><li>Vérifiez vos courriers indésirables</li></ul>`,
          });
        })
        .catch((error) => {
          let msg = "Impossible d'envoyer la demande de changement d'e-mail, veuillez nous contacter.";

          if (error.status === 409) {
            msg = "Cette adresse e-mail est déjà utilisée&nbsp;!";
          }

          this.$toasted.global.appError({
            message: msg,
          });
        });
    },

    // Update password
    updatePassword: function() {
      let payload = this.updatePasswordData;
      let userID = this.userProfileID;

      this.$store
        .dispatch("UPDATE_PASSWORD", { payload: payload, userID: userID })
        .then(() => {
          this.passwordResetSuccess = true;
          this.$toasted.global.appSuccess({
            message: "Votre mot de passe est modifié.",
          });
        })
        .catch((error) => {
          this.passwordResetSuccess = false;
          this.passwordResetToken = null;

          this.$toasted.global.appError({
            message: "Impossible de modifer le mot de passe !",
          });
        });
    },

    // Update profile
    updateUserData: function() {
      let userID = this.userProfileID;
      // this.userData.id = userID;

      let fileInput = this.$refs.avatar.files[0];
      let formData = new FormData();

      if (!!fileInput) formData.append("file", fileInput);
      formData.append("data", JSON.stringify(this.userData));

      this.$store
        .dispatch("UPDATE_USER_DATA", { userID, formData })
        .then(() => {
          this.loadUserData();

          return this.$toasted.global.appSuccess({
            message: "Vos informations ont été mise à jour.",
          });
        })
        .catch((error) => {
          return this.$toasted.global.appError({
            message: "Une erreur est survenue lors de l'enregistrement de votre profil.<br>Veuillez nous contacter pour résoudre le problème.",
          });
        });
    },

    // Load user data from database
    loadUserData: function() {
      this.$store
        .dispatch("GET_USER_DATA", this.userProfileID)
        .then((response) => {
          this.userData = response;
          // Set avatar preview
          this.avatarPreviewSrc = this.userData.avatar;
          // Set email
          this.updateEmailData.email = this.userData.email;
          // Set pro_id
          if (this.userData.rpps) this.pro_id = "rpps";
          if (this.userData.adeli) this.pro_id = "adeli";
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },

  mounted() {
    // Load & Configure Stripe JS
    this.includeStripe(
      function() {
        this.configureStripe();
      }.bind(this)
    );

    // NOTE: Get the `userProfileID` parameter value from the component's props or this.$route.params.userProfileID
    // NOTE: Only admins can view another user profile: Check if the current user ID (this.user.id) is different of the user profile id (userProfileID)
    this.loadUserData();

    // Get the email update validation token from dynamic route
    if (this.$route.params.pathMatch === "/email-update") {
      let emailValidationToken = this.$route.query && this.$route.query.token ? this.$route.query.token : null;
      this.updateEmail(emailValidationToken);
    }
  },

  watch: {
    // Reset RPPS / ADELI
    pro_id: {
      handler: function(after, before) {
        // value reset
        if (after === "rpps") {
          this.userData.adeli = "";
        }
        if (after === "adeli") {
          this.userData.rpps = "";
        }
      },
    },
  },
};
</script>
